import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import Particle from "../Particle";

import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
// import {
//   AiFillGithub,
//   AiOutlineTwitter,
//   AiFillInstagram,
// } from "react-icons/ai";
// import { FaLinkedinIn } from "react-icons/fa";
import AnimatedSocialIcons from "./AnimatedSocialIcons";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      {/* <Particle /> */}

      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              WELCOME TO <span className="purple"> MY </span> DIGITAL SPACE
            </h1>
            <p className="home-about-body">
              I'm a passionate programmer with a flair for innovation. My coding
              journey has led me through the realms of{" "}
              <b className="purple">
                {" "}
                Rust, Python, and Bash Scripting, with a focus on SQL for
                PostgreSQL and Databricks
              </b>
              .
              <br />
              <br />
              In the world of Operating Systems, I thrive in the{" "}
              <b className="purple">Linux</b> environment, particularly Debian
              and Arch. Security isn't just a protocol for me; it's a commitment
              ingrained in every line of code, with a specific focus on{" "}
              <b className="purple">TLS/SSL</b>.
              <br />
              <br />
              Navigating the landscape of Software Development & Testing, I
              orchestrate projects seamlessly using{" "}
              <b className="purple">
                Git, Jira, Confluence, TestLink, and TestRail
              </b>
              . My toolkit extends to{" "}
              <b className="purple">
                Containerization (<i>Docker</i>), Virtualization (
                <i>VMware, VirtualBox</i>), and Cloud Services like{" "}
                <i>AWS EC2, S3, Glue, and EKS</i>
              </b>
              .
              <br />
              <br />
              In the realm of Big Data and Data Engineering, I wield{" "}
              <b className="purple">Databricks</b> for Data Analytics and AI,
              complemented by a solid foundation in{" "}
              <b className="purple">PostgreSQL</b>.
              <br />
              <br />
              Join me on this journey where code meets creativity, and
              technology transforms into solutions.
              <br />
              <br />
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
            </ul>
            <AnimatedSocialIcons />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
