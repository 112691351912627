import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hey Everyone,
            <br />
            <br />I am <span className="purple">Ayush Bindlish</span>, a dynamic
            technologist hailing from
            <span className="purple"> Delhi, India </span>
            and currently thriving as a{" "}
            <span className="purple">
              Member of Technical Staff (MTS)
            </span> at <span className="purple">DataPelago, Inc.</span>,
            stationed in vibrant <span className="purple">Hyderabad</span>.
            <br />
            <br />
            Armed with an <span className="purple">MCA</span> from{" "}
            <span className="purple">Amity University</span> and a rich academic
            background in Information Technology, I've delved into the
            intricacies of{" "}
            <span className="purple">
              software development, testing, and data management
            </span>
            . With{" "}
            <span className="purple">4 years of professional experience</span>,
            I've honed my skills in{" "}
            <span className="purple">
              scripting, troubleshooting, and manual/API testing.
            </span>{" "}
            My journey has taken me through the realms of system administration,
            cloud technologies, and DevOps practices, making me adept at
            managing the entire software development lifecycle.
            <br />
            <br />
            Here's a snapshot of my technical expertise:
            <br />
            <br />
            <ul>
              <li className="about-activity">
                <ImPointRight /> Programming Languages and Scripting:
                <ul>
                  <li>Rust</li>
                  <li>Python</li>
                  <li>Bash Scripting</li>
                  <li>SQL (with a focus on PostgreSQL and Databricks)</li>
                  <li>Shell Scripting</li>
                </ul>
              </li>

              <li className="about-activity">
                <ImPointRight /> Operating Systems:
                <ul>
                  <li>Proficient in Linux (Debian and Arch)</li>
                </ul>
              </li>

              <li className="about-activity">
                <ImPointRight /> Software Development & Testing:
                <ul>
                  <li>Git</li>
                  <li>Jira</li>
                  <li>Confluence</li>
                  <li>TestLink, TestRail</li>
                </ul>
              </li>

              <li className="about-activity">
                <ImPointRight /> Containerization and Virtualization:
                <ul>
                  <li>Docker</li>
                  <li>VMware & VirtualBox</li>
                </ul>
              </li>

              <li className="about-activity">
                <ImPointRight />
                Cloud Services:
                <ul>
                  <li>AWS EC2</li>
                  <li>AWS S3</li>
                  <li>AWS Glue</li>
                  <li>AWS EKS (Elastic Kubernetes Service)</li>
                </ul>
              </li>

              <li className="about-activity">
                <ImPointRight />
                Big Data and Data Engineering:
                <ul>
                  <li>Databricks (Data Analytics and AI)</li>
                  <li>PostgreSQL</li>
                </ul>
              </li>
            </ul>
          </p>
          <br />
          <br />
          <br />

          <p style={{ color: "rgb(155 126 172)" }}>
            "Theory and practice sometimes clash. And when that happens, theory
            loses. Every single time."{" "}
          </p>
          <footer className="blockquote-footer">Linus Torvalds</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
