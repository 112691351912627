import React from "react";
import { Col, Row } from "react-bootstrap";
import { DiVim, DiRasberryPi } from "react-icons/di";
import {
  SiVisualstudiocode,
  SiPostman,
  SiJetbrains,
  SiVirtualbox,
  SiVmware,
} from "react-icons/si";

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <SiJetbrains />
        <div className="text">Jetbrains IDEs</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiVisualstudiocode />
        <div className="text">VS Code</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPostman />
        <div className="text">Postman</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiVim />
        <div className="text">Vim Editor</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiVirtualbox />
        <div className="text">VirtualBox</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiVmware />
        <div className="text">Vmware</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiRasberryPi />
        <div className="text">Raspberry Pi</div>
      </Col>
    </Row>
  );
}

export default Toolstack;
