import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { AiOutlineDownload } from "react-icons/ai";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
// import Particle from "../Particle";
import "./YourCustomStyles.css"; // Import your custom styles
import pdfPath from "../../Assets/AyushBindlish_QA_MTS_Resume.pdf";
// Set the PDF worker path
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

// Define the PDF file path
// const pdfPath = "/path/to/AyushBindlish_QA_MTS_Resume.pdf";

function ResumeNew() {
  useEffect(() => {
    // Any side effect code you want to run on mount
  }, []);

  const onLoadError = (error) => {
    console.error("Error loading PDF:", error);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    console.log(`Document loaded successfully - ${numPages} pages`);
  };

  return (
    <div>
      <Container fluid className="resume-section">
        {/* <Particle /> */}

        <Row className="justify-content-center">
          <Button
            variant="primary"
            href={pdfPath}
            target="_blank"
            style={{ maxWidth: "250px", margin: "20px 0" }}
          >
            <AiOutlineDownload />
            &nbsp;Download CV
          </Button>
        </Row>

        <Row className="resume justify-content-center">
          <Container className="pdf-container">
            {/* Add onDocumentLoadSuccess to handle successful document load */}
            <Document
              file={pdfPath}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={onLoadError}
              className="react-pdf__Document"
            >
              {Array.from(new Array(1), (el, index) => (
                <Page
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                  key={`page_${index + 1}`}
                  className="react-pdf__Page pdf-page"
                  pageNumber={index + 1}
                />
              ))}
            </Document>
          </Container>
        </Row>

        <Row className="justify-content-center">
          <Button
            variant="primary"
            href={pdfPath}
            target="_blank"
            style={{ maxWidth: "250px", margin: "20px 0" }}
          >
            <AiOutlineDownload />
            &nbsp;Download CV
          </Button>
        </Row>
      </Container>
    </div>
  );
}

export default ResumeNew;
