import React from "react";
// import React, { useEffect, useState} from "react";

import GitHubCalendar from "react-github-calendar";
// import GitlabCalendar from 'gitlab-calendar';

import { Row } from "react-bootstrap";

function Github() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
      <h1 className="project-heading" style={{ paddingBottom: "20px" }}>
        Days I've <strong className="purple">Coded</strong>
      </h1>
      <GitHubCalendar
        username="ayushbindlish"
        blockSize={15}
        blockMargin={5}
        color="#c084f5"
        fontSize={16}
      />
    </Row>
  );
}

// function GitLab() {
//   const [events, setEvents] = useState([]);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const user = "ayushbindlish";
//         const fromDate = "2023-01-01"; // Set your desired start date
//         const toDate = "2023-12-31"; // Set your desired end date
//         const apiUrl = `https://gitlab.com/api/v4/users/${user}/events?after=${fromDate}&before=${toDate}`;

//         const response = await fetch(apiUrl, {
//           headers: {
//             "PRIVATE-TOKEN": "glpat-989mp2pWhHsArWk8x-DS",
//           },
//         });

//         if (response.ok) {
//           const events = await response.json();
//           setEvents(events);
//         } else {
//           console.error("Error fetching GitLab events:", response.statusText);
//         }
//       } catch (error) {
//         console.error("Error fetching or rendering GitLab calendar", error);
//       }
//     };

//     fetchData();
//   }, []); // Empty dependency array ensures the effect runs only once on component mount

//   return (
//     <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
//       <h1 className="project-heading" style={{ paddingBottom: "20px" }}>
//         Days I've <strong className="purple">Code</strong>
//       </h1>
//       <div id="gitlab-calendar">
//         <GitlabCalendar events={events} fetchData={GitLab.fetchData} />
//       </div>
//     </Row>
//   );
// }

const GitComponents = {
  Github,
  // , GitLab
};

export default GitComponents;
