import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  DiRust,
  DiMongodb,
  DiPython,
  DiGit,
  DiPostgresql,
  DiDocker,
} from "react-icons/di";
import {
  SiGnubash,
  SiKubernetes,
  SiUbuntu,
  SiArchlinux,
  SiAmazonaws,
  SiDatabricks,
} from "react-icons/si";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <DiRust />
        <div className="text">Rust</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiPython />
        <div className="text">Python</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiPostgresql />
        <div className="text">PostgreSQL</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiGit />
        <div className="text">Git</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiGnubash />
        <div className="text">Bash Scripting</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiMongodb />
        <div className="text">MongoDB</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiKubernetes />
        <div className="text">Kubernetes</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiUbuntu />
        <div className="text">Ubuntu</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiArchlinux />
        <div className="text">Arch Linux</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiAmazonaws />
        <div className="text">AWS</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiDatabricks />
        <div className="text">Databricks</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiDocker />
        <div className="text">Docker</div>
      </Col>
      {/* <Col xs={4} md={2} className="tech-icons">
        <DiPython />
        <div className="text">Python</div>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiJava />
        <div className="text">Java</div> */}
      {/* </Col> */}
    </Row>
  );
}

export default Techstack;
